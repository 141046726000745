export interface INavigationRoute {
  name: string
  displayName: string
  meta: { icon: string }
  children?: INavigationRoute[]
}

export default {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
  },
  routes: [
    {
      name: 'dashboard',
      displayName: 'menu.dashboard',
      meta: {
        icon: 'vuestic-iconset-dashboard',
      },
    },
    {
      name: 'users',
      displayName: 'menu.users: '+' :template default',
      meta: {
        icon: 'group',
      },
    },
    {
      name: 'map',
      displayName: 'menu.maps',
      meta: {
        icon: 'pin_drop',
      },
    },
    {
      name: 'crud-users',
      displayName: 'Users',
      meta: {
        icon: 'edit_note',
      },
    },
    {
      name: 'crud-gas-stations',
      displayName: 'Gas Stations',
      meta: {
        icon: 'edit_note',
      },
    },
    {
      name: 'crud-companies',
      displayName: 'Companies',
      meta: {
        icon: 'edit_note',
      },
    },
    // {
    //   name: 'crud-endusers',
    //   displayName: 'Endusers',
    //   meta: {
    //     icon: 'edit_note',
    //   }
    // },
    {
      name: 'crud-pumps',
      displayName: 'Pumps',
      meta: {
        icon: 'edit_note',
      },
    },
    {
      name: 'crud-fuel-types',
      displayName: 'Fuel Types',
      meta: {
        icon: 'edit_note',
      },
    },
    {
      name: 'crud-vehicle-plates',
      displayName: 'Vehicle Drivers',
      meta: {
        icon: 'edit_note',
      }
    },
    {
      name: 'crud-stations-for-plates',
      displayName: 'Stations for Plates',
      meta: {
        icon: 'edit_note',
      }
    },
    {
      name: 'crud-transactions',
      displayName: 'Transactions',
      meta: {
        icon: 'edit_note',
      }
    },
    {
      name: 'crud-gs-middleware',
      displayName: 'GS Middleware',
      meta: {
        icon: 'edit_note',
      },
    },
    {
      name: 'companies-view',
      displayName: 'Companies View',
      meta: {
        icon: 'dataset',
      }
    },
    {
      name: 'plates-view',
      displayName: 'Plates View',
      meta: {
        icon: 'dataset',
      }
    },
    {
      name: 'projects',
      displayName: 'menu.projects',
      meta: {
        icon: 'folder_shared',
      },
    },
    {
      name: 'payments',
      displayName: 'menu.payments',
      meta: {
        icon: 'credit_card',
      },
      children: [
        {
          name: 'payment-methods',
          displayName: 'menu.payment-methods',
        },
        {
          name: 'pricing-plans',
          displayName: 'menu.pricing-plans',
        },
        {
          name: 'billing',
          displayName: 'menu.billing',
        },
      ],
    },
    {
      name: 'auth',
      displayName: 'menu.auth',
      meta: {
        icon: 'login',
      },
      children: [
        {
          name: 'login',
          displayName: 'menu.login',
        },
        {
          name: 'signup',
          displayName: 'menu.signup',
        },
        {
          name: 'recover-password',
          displayName: 'menu.recover-password',
        },
      ],
    },

    {
      name: 'faq',
      displayName: 'menu.faq',
      meta: {
        icon: 'quiz',
      },
    },
    {
      name: '404',
      displayName: 'menu.404',
      meta: {
        icon: 'vuestic-iconset-files',
      },
    },
    {
      name: 'preferences',
      displayName: 'menu.preferences',
      meta: {
        icon: 'manage_accounts',
      },
    },
    {
      name: 'settings',
      displayName: 'menu.settings',
      meta: {
        icon: 'settings',
      },
    },
  ] as INavigationRoute[],
}
