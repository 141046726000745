import axios from 'axios';

// const baseURL = 'http://127.0.0.1:5000/';
const baseURL = 'https://autofuel.gr/api/prod/'

export { baseURL };

const SERVICE_TOKEN = "f518262ee1543b01c134d85643aa0507";

// Create an Axios instance with the base URL
const instance = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Create a separate Axios instance for refreshing the JWT token
const refreshInstance = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add request interceptor for JWT token and X-Service-Token
instance.interceptors.request.use(
  (config) => {
    const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTczNDExMDYzMCwianRpIjoiNWJhMDRjZGYtNGFmNS00ZTAyLWEzOTgtZWIwYzI1MGU2MTk2IiwidHlwZSI6ImFjY2VzcyIsInN1YiI6InVzZXJAbWFpbC5jb20iLCJuYmYiOjE3MzQxMTA2MzAsImNzcmYiOiIyYWQ1NWJhNy1kNDkwLTQ3OTAtODY0NC1jNTllNjBiNTU0ZWUiLCJleHAiOjE3MzQxOTcwMzAsInJvbGUiOiJzeXNhZG1pbiJ9.HoUskUSw4uWxQVvXCCg3zboJwHig8Oeh9mJsSbE8U4M';
    const refreshToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTczNDAwODQ0OCwianRpIjoiMTNkZTNkOGYtY2NiMi00MTUxLTg2NzctMGFlMDk5YjZmOWJmIiwidHlwZSI6InJlZnJlc2giLCJzdWIiOiJ1c2VyQG1haWwuY29tIiwibmJmIjoxNzM0MDA4NDQ4LCJjc3JmIjoiNjYzMDNjNmQtNTUyMy00ZTg5LThhMWUtYjY2ZWY0NGQzMjI1IiwiZXhwIjoxNzQwMDU2NDQ4LCJyb2xlIjoic3lzYWRtaW4ifQ.L1AFPFfXxbr1mq16kgRMpoGwESqBje8G_f4zdj-XIEc';
    const serviceToken = SERVICE_TOKEN;
    if (token && !config.headers['X-Service-Token']) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    if (refreshToken) {
      config.headers['X-Refresh-Token'] = refreshToken;
    }
    if (serviceToken) {
      config.headers['X-Service-Token'] = serviceToken;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  response => response,
  async error => {
    console.log('Error response:', error.response);
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const refreshToken = localStorage.getItem('refresh_token');
        if (!refreshToken) {
          return Promise.reject(error);
        }

        const refreshResponse = await refreshInstance.post('/auth/refresh', {}, {
          headers: {
            Authorization: `Bearer ${refreshToken}`,
          },
        });

        const { access_token } = refreshResponse.data;
        localStorage.setItem('access_token', access_token);

        originalRequest.headers.Authorization = `Bearer ${access_token}`;

        return instance(originalRequest);
      } catch (refreshError) {
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

export default instance;
